import React from 'react';
import { graphql } from 'gatsby';
import { Col, Container, Row } from 'react-bootstrap';
import { Layout } from '../components/layout';

const Blog = ({ data }) => {
  const { markdownRemark: blog } = data;

  return (
    <Layout>
      <Container>
        <Row className="py-5">
          <Col>
            <div dangerouslySetInnerHTML={{ __html: blog.html }}></div>
          </Col>
          <Col className="d-none d-sm-block" xs={4} md={4}>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default Blog;

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        thumbnail {
          childImageSharp {
            fixed(width: 250, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        cover {
          childImageSharp {
            fluid(maxWidth: 1280, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;